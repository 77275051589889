<template>
    <div class="row">
        <b-form-group
            label='ФИО студента'
            class='col-4'
            label-for="fio"
        >
            <b-form-input
                id="fio"
                type="text"
                placeholder="Имя ученика"
                autocomplete="off"
                readonly
                v-model="account.full_name"
            />
        </b-form-group>

        <b-form-group
            label='Пол'
            class='col-4'
            label-for="sex"
        >
            <b-form-input
                id="sex"
                type="text"
                placeholder="Пол"
                autocomplete="off"
                readonly
                v-model="studentGender"
            />
        </b-form-group>

        <b-form-group
            label='Дата рождения'
            class='col-4'
            label-for="birthdate"
        >
            <b-form-input
                id="birthdate"
                type="text"
                placeholder="Дата рождения"
                autocomplete="off"
                readonly
                v-model="studentBirthdate"
            />
        </b-form-group>

        <b-form-group
            label='Возраст'
            class='col-4'
            label-for="age"
        >
            <b-form-input
                id="age"
                type="text"
                placeholder="Возраст"
                autocomplete="off"
                readonly
                v-model="account.age"
            />
        </b-form-group>

        <b-form-group
            label='Возрастная группа'
            class='col-4'
            label-for="age"
        >
            <b-form-input
                id="age"
                type="text"
                placeholder="Возрастная группа"
                autocomplete="off"
                readonly
                v-model="account.age_group"
            />
        </b-form-group>

        <b-form-group
            label='Имя родителя'
            class='col-4'
            label-for="parent_name"
        >
            <b-form-input
                id="parent_name"
                type="text"
                placeholder="Имя родителя"
                autocomplete="off"
                readonly
                v-model="account.parent_name"
            />
        </b-form-group>

        <b-form-group
            label='Страна'
            class='col-4'
            label-for="country"
        >
            <b-form-input
                id="country"
                type="text"
                placeholder="Страна"
                autocomplete="off"
                readonly
                v-model="studentCountry"
            />
        </b-form-group>

        <b-form-group
            label='Часовой пояс'
            class='col-4'
            label-for="timezone"
        >
            <b-form-input
                id="timezone"
                type="text"
                placeholder="Часовой пояс"
                autocomplete="off"
                readonly
                v-model="account.timezone"
            />
        </b-form-group>

        <b-form-group
            label='Игровой опыт'
            class='col-4'
            label-for="experience"
        >
            <b-form-input
                id="experience"
                type="text"
                placeholder="Игровой опыт"
                autocomplete="off"
                readonly
                v-model="account.experience_title"
            />
        </b-form-group>
    </div>
</template>
<script>
import ScheduleListComponent from "@/components/admin/lessons/ScheduleListComponent.vue";
import LessonsListComponent from "@/components/admin/lessons/LessonsListComponent.vue";
import moment from "moment/moment";
import {mapGetters} from "vuex";

export default {
    name: "StudentsProfileForm",
    components: {LessonsListComponent, ScheduleListComponent},
    data () {
        return {
            account: {
                full_name : 'Не установлено',
                birthdate : 'Не установлено',
                age : 'Не установлено',
                age_group : 'Не установлено',
                parent_name : 'Не установлено',
                timezone : 'Не установлено',
                experience_title : 'Не установлено',
                avatar_url: 'https://place-hold.it/200x200',
            },
        }
    },
    created() {
        this.account = {...this.account, ...this.getStudentDetail}
        this.getAgeGroupTitle(this.account.age_group)
        this.setUnselectText()
    },
    computed: {
        moment() {
            return moment
        },
        ...mapGetters({
            getStudentDetail: "students/getStudentDetail",
            getCountriesCode: "common/getCountriesCodes",
            getAgeGroups: "common/getAgeGroups",
        }),
        baseUrl() {
            return process.env.VUE_APP_API_BASE_URL;
        },
        studentGender() {
            let studentGender = this.getStudentDetail.gender ? 'Мужской' : 'Женский'
            if (this.getStudentDetail.gender === null) studentGender = 'Не выбран'
            return studentGender
        },
        studentBirthdate() {
            return this.account.birthdate === 'Не установлено' ? this.account.birthdate : moment(this.account.birthdate).format('DD.MM.YYYY')
        },
        studentCountry() {
            let studentCountryCode = this.getStudentDetail.country_code
            let CountriesCode = this.getCountriesCode
            let studentCountryCodeTitle = 'Не установлено'

            if (!(studentCountryCode || CountriesCode)) {
                return studentCountryCodeTitle
            }

            for (const countriesCodeValue of CountriesCode) {
                if ( countriesCodeValue.value === studentCountryCode){
                    studentCountryCodeTitle = countriesCodeValue.text
                    break
                }
            }

            return studentCountryCodeTitle
        },
    },
    methods: {
        getAgeGroupTitle(age_group) {
            this.getAgeGroups.forEach(item => {
                console.log(item)
                if (item.value === age_group) {
                    this.account.age_group = item.text
                }
            })
        },
        setUnselectText() {
            for (const [key, value] of Object.entries(this.account)) {
                !this.account[key] ? this.account[key] = 'Не установлено': this.account[key] = value;
            }
        },
        async handlePageSelect(bvEvent, page) {
            if (page) {
                const query = Object.assign({}, this.$route.query, { page });
                await this.$router.push({ query: query });
            }

            return false;
        },
    }
}
</script>


<style scoped lang="scss">

</style>